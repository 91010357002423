/* For use in src/lib/core/theming/_palette.scss */
@use 'sass:map';
@use '@angular/material' as mat;
// $primary-palette: 
$primary-palette-colors: (
    50 : #e1e2eb,
    100 : #b5b6cd,
    200 : #8386ac,
    300 : #51568b,
    400 : #2c3172,
    500 : #070d59,
    600 : #060b51,
    700 : #050948,
    800 : #04073e,
    900 : #02032e,
    A100 : #6767ff,
    A200 : #3434ff,
    A400 : #0101ff,
    A700 : #0000e6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$primary-palette: mat.define-palette($primary-palette-colors);