$brand-primary: #070D59;
$brand-secondary: #00c1d4;
$active-color: #3b00dd;
$brand-black: rgb(0, 0, 0);
$brand-light-white: rgba(255, 255, 255, 0.6);
$brand-light-black: rgba(0, 0, 0, 0.6);
$white: #ffffff;
$black: #000000;
$light-grey: rgba(0, 0, 0, 0.54);
$lightest-grey: #e4e8ee;
$green-6: #009900;
$error-bg: rgb(255, 240, 239);
$error-color: #323232;
$error-color-2: rgb(244, 67, 54);
$error-color-3: rgb(227, 109, 104);
$black-1: rgb(52, 55, 51);
$success-bg: rgb(243, 255, 237);
$success-bg-1: #03dac4;
$caribbean-green: #02c39a;
$dc-data-type: #008464;
$warning: #ff8100;
$skipped: #ffd400;
$violet: #6D61F6;
// Dark Theme Colors
$brand-dark: #051c2c;
$brand-dark-shade: #2245c3;
$side-nav-active: #61ccf2;

// revenue leaks colors
$revleaks-pink: #6558f5;
$revleaks-green: #1aae9f;
$revleaks-venetian-red: #D3455B;

// revenue leaks initiative colors
$revleaks-init-green: #00b1a2;
$revleaks-init-red: #e93752;
$revleaks-init-black: #233845;
$revleaks-init-orange: #fb7d2c;
$revleaks-init-gray: #738996;
$revleaks-init-dark-blue: #070d59;
$revleaks-init-light-blue: #4588D9;
$revleaks-init-violet: #6D61F6;
$revleaks-init-black-shade: #43505c;

// alerts dashboard colors
$alerts-dashboard-red: #ff4747;
$alerts-dashboard-gray: #738996;

//revops gradiant colors
$revops-gradiant-light-red: #ff4747;
$revops-gradiant-dark-red:#cb0000;

//revenue leak metric info colors
$revops-metric-info-red: #D3455B;
$revops-metric-info-green: #55AF9F;
$revops-metric-info-black: #4B5C6B;

//new query editor colors
$query-editor-light-black: #383838;
$query-editor-light-black1: #262626;
