@use 'variables';
@mixin font-roboto($type: '', $size: '', $weight: '', $color: '', $lh: '') {
  @if $type == medium {
    // font-family: 'Roboto Medium', Helvetica, Arial, sans-serif;
  } @else {
    // font-family: 'Roboto', Helvetica, Arial, sans-serif;
  }
  @if $size != '' {
    font-size: $size;
  }
  @if $color != '' {
    color: $color;
  }
  @if $weight != '' {
    font-weight: $weight;
  }
  @if $lh != '' {
    line-height: $lh;
  }
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin mat-table-columns($columns) {
  .mat-column- {
    @each $colName, $props in $columns {
      $width: map-get($props, 'width');

      &#{$colName} {
        flex: $width;
        min-width: $width;

        @if map-has-key($props, 'color') {
          color: map-get($props, 'color');
        }
      }
    }
  }
}
/**
  Use: @include mat-table-columns((

    orderid: (width: 6rem, color: gray),
    date: (width: 9rem),
    items: (width: 20rem)

));
*/

/**
* fall back empty item component style
*/
@mixin empty-component-style {
  :host {
    display: flex;
    margin: 20px;
    font-weight: 500;
  }
}

/**
* new item component style
*/
@mixin new-component-style {
  :host {
    display: block;
    border-radius: 1px;
    height: 100%;
    .mat-dialog-title,
    form {
      padding: 20px;
    }
    form {
      .mat-form-field {
        &:first-child {
          margin-top: 10px;
        }
      }
    }
  }
}

@mixin dialog-component-style {
  .mdc-dialog__title {
    padding-left: 20px;
  }
  &:has(.mat-sub-title) {
    .mdc-dialog__title {
      padding-bottom: 0;
      line-height: 0;
    }
  }
  .mat-sub-title {
    font-size: 12px;
    padding-left: 20px;
    color: variables.$light-grey;
  }
  .mat-dialog-content {
    padding: 20px;
  }
  form {
    .mat-form-field {
      &:first-child {
        margin-top: 10px;
      }
    }
  }
}

@mixin custom-component-configs() {
  :host {
    .config-options.mat-mdc-card {
      padding: 0;
      margin-bottom: 30px;
      .mat-card-title {
        height: 33px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e4e4e4;
        padding: 5px 15px;
        align-items: center;
        .mat-checkbox {
          margin-bottom: 10px;
          display: block;
        }
      }
      .mat-card-content {
        padding: 5px 15px;
        .fullwidth {
          width: 100%;
        }
        .mat-radio-group,
        .mat-checkbox {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@mixin ngx-data-table {
  :host {
    .autofit-table-wrapper {
      height: calc(100vh - 120px);
      overflow-y: auto;
    }
    .ngx-datatable {
      box-shadow: none;
    }
    .action-btn {
      margin-top: -10px;
    }
  }
}

@mixin small-mat-form-field {
  // ::ng-deep .small-mat-form-field .mat-form-field-wrapper {
  //   margin-top: 0px;
  //   padding: .7px 0px;
  //   .mat-select > .mat-select-trigger > .mat-select-arrow-wrapper {
  //     transform: translateY(0%) !important;
  //   }
  // }
  // ::ng-deep .small-mat-form-field.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  //   transform: translateY(-1.1em) scale(.75);
  //   width: 133.33333%;
  // }

  // ::ng-deep .small-mat-form-field .mat-form-field-wrapper .mat-form-field-flex > .mat-form-field-infix {
  //   padding: .2em 0 .7em 0 !important;
  // }
  // ::ng-deep .small-mat-form-field .mat-form-field-label-wrapper {
  //   top: -1.5em;
  // }

  ::ng-deep
    .small-mat-form-field.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-1.1em) scale(0.75);
    width: 133.33333%;
  }

  ::ng-deep .small-mat-form-field .mat-form-field-wrapper {
    margin: 0px;
    padding: 0px 10px;
    // width: 160px;
  }
  ::ng-deep .small-mat-form-field {
    &.show-error .mat-form-field-wrapper {
      padding-bottom: 17px;
    }
  }

  ::ng-deep
    .small-mat-form-field
    .mat-form-field-wrapper
    .mat-form-field-flex
    > .mat-form-field-infix {
    padding: 0.4em 0px !important;
  }
  ::ng-deep .small-mat-form-field .mat-form-field-label-wrapper {
    top: -1.5em;
  }
  ::ng-deep .mat-form-field-type-mat-select .mat-form-field-label {
    height: calc(100% - 20px);
  }
  ::ng-deep .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 14px;
  }

  ::ng-deep .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-23.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    transform: var(--mat-mdc-form-field-label-transform);
  }
}

@mixin table-operations-header {
  .header .actions {
    display: none;
    color: variables.$black;
    // position: relative;
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
  }

  .header .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header:hover .actions {
    display: inline-block;
  }

  .header:hover .text {
    max-width: 75% !important;
  }

  .filter-added-icon {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 15px;
  }

  .sort-added-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 15px;
  }

  .right-icon {
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
  }

  .hidden_header {
    visibility: hidden;
  }
}

@mixin alerts-reports-listing {
  .header-text {
    color: variables.$brand-black !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  ::ng-deep .ag-header-cell .ag-header-cell-text,
  ::ng-deep app-ag-grid-custom-header .text {
    color: variables.$brand-black !important;
    font-size: 14px !important;
  }

  .header-name,
  ::ng-deep .ag-row .ag-cell .header-name {
    color: variables.$brand-dark-shade;
    font-size: 16px;
    font-weight: 500;
  }

  .badge,
  ::ng-deep .ag-row .ag-cell .badge {
    padding: 4px 7px 4px 7px;
    font-size: 12px;
    font-weight: 500;
  }

  .label-icon {
    position: absolute;
    top: 7px;
    font-size: 25px;
    height: 25px;
    width: 25px;
  }
  ::ng-deep .ag-row .ag-cell .icon-in-grid {
    position: absolute;
    top: 5px;
  }

  .align-icon {
    top: 4px;
    margin-left: -6px;
  }

  ::ng-deep .ag-row .ag-cell .align-icon {
    top: 5px;
    margin-left: -8px;
  }
  .align-tag {
    padding-right: 10px;
  }
  ::ng-deep .ag-row .ag-cell .align-tag {
    top: 3px;
    position: relative;
    padding-right: 10px;
  }
}
