@import 'variables';
.mat-snack-bar-container {
  &.shadow {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  &.unset-margin {
    margin: 15px !important;
  }

  &.green-snackbar {
    background: $success-bg-1;
    color: $white;
  }

  &.dark-green-snackbar {
    background: $green-6;
    color: $white;
  }

  &.red-snackbar {
    background: $error-bg;
    color: $black-1;
  }

  &.red-snackbar-2 {
    background: $error-color-2;
    color: $white;
  }
}
