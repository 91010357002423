// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'mixins';
@use 'variables';
@use 'snackbar';
@use 'scss/theme';
// @import '@angular/material/theming';
// @import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
// @import '~@swimlane/ngx-datatable/index.css';
// @import '~@swimlane/ngx-datatable/themes/material.css';
// @import '~@swimlane/ngx-datatable/assets/icons.css';

//import UI range slider style.
// @import "~nouislider/distribute/nouislider.min.css";

// Import ag-grid styles.
@import "ag-grid-community/styles/ag-grid.css";
// @import "@ag-grid-community/styles/ag-theme-quartz.css";
// @import "ag-grid-community/dist/styles/ag-grid.css";
// @import "ag-grid-community/dist/styles/ag-theme-balham.css";
@import "ag-grid-community/styles/ag-theme-material.css";

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
@include mat.core();
@include theme.bli-theme();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $biglittle-primary: mat.define-palette(primary.$bli-primary-color);
// $biglittle-accent: mat.define-palette(mat.$teal-palette, A700, A100, A400);

// The warn palette is optional (defaults to red).
// $biglittle-warn: mat.define-palette(mat.$red-palette);

// typography
$fontFamily: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

// font familty
// $bli-typography: mat.define-typography-config(
//   $font-family: $fontFamily
// );


// Create the theme object (a Sass map containing all of the palettes).
// $biglittle-theme: mat.define-light-theme((
//   color: (
//     primary: $biglittle-primary,
//     accent: $biglittle-accent,
//     warn: $biglittle-warn
//   ),
//   density: 0,
//   typography: $bli-typography
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.


// @include mat.all-component-themes($biglittle-theme);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($bli-typography);`
// @include mat.all-legacy-component-typographies($bli-typography);
// @include mat.legacy-core();

/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/dist/css/bootstrap.css';

@import 'ngx-toastr/toastr';

// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';
// @import '~ngx-toastr/toastr-bs4-alert';

html,
body {
  height: 100%;
  // font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-family: $fontFamily;
  color: variables.$brand-black;
  max-width: 100%;
  overflow-x: hidden !important;
}
body {
  margin: 0;  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
img {
  max-width: 100%;
}
// .bli-primary-btn {
//   background-color: $brand-primary;
//   text-transform: uppercase;
//   color: $white;
//   font-weight: 400;
//   height: 48px;
//   &[disabled] {
//     background-color: rgba(0, 0, 0, 0.26);
//   }
//   &.small-height {
//     height: 36px;
//   }
// }

.bli-primary-btn-capt {
  background-color: variables.$brand-primary;
  text-transform: capitalize;
  color: variables.$white;
  font-weight: 400;
  height: 48px;
  &[disabled] {
    background-color: rgba(0, 0, 0, 0.26);
  }
  &.small-height {
    height: 36px;
  }
}


.bli-secondary-btn {
  background-color: variables.$brand-secondary;
  text-transform: uppercase;
  color: variables.$white;
  font-weight: 400;
  height: 48px;
  &[disabled] {
    background-color: rgba(0, 0, 0, 0.26);
  }
  &.small-height {
    height: 30px;
    line-height: 30px;
  }
}

.bli-primary {
  background-color: variables.$brand-primary;
  color: variables.$white;
}

.bli-primary-checkbox {
  &.mat-checkbox-checked .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0.03;
  }
  &.mat-checkbox-checked .mat-checkbox-ripple .mat-ripple-element,
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, 
  &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: variables.$brand-secondary;
  }
  &.mat-checkbox-disabled .mat-checkbox-background{
    background-color: #b0b0b0 !important;
    border: none !important;
  }
}

.bli-primary-slider {
  .mat-slider-track-fill, 
  .mat-slider-thumb, 
  .mat-slider-thumb-label {
    background-color: variables.$brand-secondary;
  }
  &.bli-disabled {
    .mat-slider-track-fill, 
    .mat-slider-thumb, 
    .mat-slider-thumb-label {
      background-color: variables.$brand-light-black;
    }
  }
}

a {
  color: variables.$brand-primary;
}

.ngx-datatable {
  width: 100%;
}

::ng-deep .datatable-header {
  .datatable-header-cell-label {
    font-size: 14px !important;
  }
}

.bli-context-menu {
  .mat-menu-item {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
}

.side-sheet {
  position: fixed !important;
  right: 0;
  height: 100%;
  min-width: 310px;
  @media screen and (min-width: 599px) {
    width: 400px;
  }
  .mat-dialog-container {
    border-radius: 0;
    padding: 0;
    height: calc(100% - 45px);
    .mat-dialog-content,
    .mat-sheet-header {
      padding: 0 15px;
    }
    .mat-sheet-header {
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: 2px solid variables.$lightest-grey;
    }
    margin-top: 46px;
    .mat-dialog-title {
      margin: 0;
      font-weight: 400;
    }
  }
}

.mat-dialog-center-sheet {
    .mat-dialog-container {
      border-radius: 0;
      padding: 0;
    }
}

.revops-dialog-class {
  .mat-dialog-container {
    overflow: hidden;
    padding: 0 !important;
  }
}
.dialog-full-width {
  max-width: 100vw !important;
}

.cdk-global-scrollblock {
  overflow: hidden !important;
}
.cdk-overlay-pane.fit-content { 
  width: fit-content !important;
}

.ag-theme-material, .ag-theme-balham {
  .ag-cell {
    font-size: 10px;
    line-height: 30px;
    // padding-left: 10px;
    padding-right: 10px;
    transition: background-color 0.5s ease;
    &.cell-highlighed {
      transition: background-color 0.5s ease;
      background-color: variables.$brand-secondary;
    }
    &.ag-cell-focus {
      transition: border 0.3s ease;
    }
    .bar-chart-wrp {
      width: 130px;
      display: inline-block;
    }
    .bar-chart {
      display: inline-block;
      height: 8px;
      background-color: variables.$brand-secondary;
      position: relative;
      top: 0px;
      left: 5px;
    }
    .bar-value {
      display: inline-block;
      width: 40px;
    }
  }
  .ag-header-cell {
    // font-size: 11px;
    // color: rgba(0, 0, 0);
    // padding-left: 10px;
    // padding-right: 10px;
  }
  .ag-row .ag-cell {
    font-family: $fontFamily;
    font-size: 14px;
  }
}

.mat-select,
.mat-option-text {
  .right-datatype-section {
    color: variables.$light-grey;
    font-size: 12px;
    margin-right: 10px;
    text-transform: capitalize;
  }
}
.mdc-list-item__primary-text {
  font-size: 14px;
}

footer{
  border-top:2px solid rgba(0,0,0,0.09); 
  height: 44px;
  padding-top: 10px;
  text-align:right;
}

.custom-panel {
  max-height: 200px; // mat-select-panel height = 256px. 200 + 10 + 44 + 2
  overflow: auto;
}

.custom-panel mat-option.mat-option {
  height: unset;
    max-height: calc(1.8em * 4); margin: 0;
  font-size: 16px;
  line-height:1.8em; 
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal !important;
  word-break: break-all;
  padding-top: 2px;
  padding-bottom: 2px;
}

.custom-panel mat-pseudo-checkbox.mat-pseudo-checkbox {
  align-self: center;
  margin-top: 5px;
}

.custom-panel mat-option.mat-option:first-child {
  padding-top: 10px;
}

.custom-multi-search-panel {
  .search-wrapper {
    height: 50px;
    mat-icon {
      padding: 10px 10px;
    }
  }
  .mat-mdc-optgroup { 
    .mat-mdc-optgroup-label {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

#atlwdg-trigger {
  display: none;
}
.keywords {
	color: orange !important;
 
	font-weight: bold;
	font-style: oblique;
}


.discoverytables {
	color: red !important;
 
	font-weight: bold;
	font-style: oblique;
}


.tableschema {
	color: green !important;
 
	font-weight: bold;
	font-style: oblique;
}

.width-130px {
  width: 130px;
}

.width-90 {
  max-width: 90%;
}

.filterPanel{
  min-width: calc(100% + 20px) !important;
}
div.mat-mdc-select-panel.mat-select-panel-above {
  bottom: auto !important;
  position: absolute;
  top: -200px !important;
}

.md-drppicker .ranges ul li button[disabled] {
  opacity: 1 !important;
}
.double-daterange-picker .md-drppicker {
  box-shadow: none !important;
  button.btn {
    border-radius: 4px;
    width: 75px;
    background-color: #070D59;
  }
  button.active {
    background-color: #070D59;
  }
}

.revleaks-collab-comment__email-dropdown {
  line-height: 1.9;
  span[contenteditable=false] {
    background-color: #DEEBFF;
    border-radius: 20px;
    border: 1px solid #0065FF;
    padding: 0px 0.3em 2px 0.23em;
    font-size: 1em;
    line-height: 1.714;
  }
  br,
  div {
    font-size: 1em;
    line-height: 1.714;
  }
}

.custom-action-btn {
  max-width: 30ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.padding-lr-20 {
  padding: 0 20px
}


.revleaks-assign-btn {
  border: 2px solid transparentize(variables.$revleaks-pink, 0.5);
  border-radius: 4px;
  min-height: 36px;
  color: variables.$revleaks-pink;
  background: variables.$white;
  &.icon {
    border-radius: 0;
    padding: 0;
    min-height: 40px;
    min-width: 40px !important;
    background: transparent;
  }
  cursor: pointer;
  &.assigned {
    border-color: transparentize(variables.$revleaks-green, 0.5);
    color: variables.$revleaks-green;
  }
  mat-icon {
    color: variables.$revleaks-pink;
    &.assigned {
      color: variables.$revleaks-green;
    }
  }
  &[disabled] {
    background-color: variables.$lightest-grey !important;
  }
}

ngx-mat-timepicker {
  .mat-form-field-appearance-legacy {
    .mat-form-field-flex {
      padding-left: 1px !important;
      width: 35px !important;
      max-width: 35px !important;
    }
  }
}

.curved-badge,
.ag-row .ag-cell .curved-badge {
  border-radius: 0.5em !important;
}

// small form field size
.form-field-sm,
.mat-form-field-sm {
  font-size: 14px !important;
  .mat-mdc-form-field-infix {
    padding: 4px 0 !important;
    min-height: 28px !important;
  }
  .mat-mdc-form-field-icon-suffix {
    .mat-icon {
      padding: 0 12px;
    }
  }
  .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
    top: 14px !important;
  }
  .mat-mdc-select-min-line {
    font-size: 13px;
  }
  &.hide-subscript-wrapper {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.mat-datepicker-content {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.datatable-icon-prev::before {
  content: '<<' !important;
}
.datatable-icon-left::before {
  content: '\02C2' !important;
}

.datatable-icon-right::before {
  content: '\02C3' !important;
}

.datatable-icon-skip::before {
  content: '>>' !important;
}

.datatable-icon-down::before {
  content: '\25BE' !important;
}

.datatable-icon-up::before {
  content: '\25B4' !important;
}

.visibility-hidden-h-0 {
  visibility: hidden;
  height: 0;
}

.white-overlay-background {
    background-color: variables.$white !important;
}

.variant-select-list {
  max-width: 100%; 
  overflow-x: scroll;
}


.api-error-message {
  padding: 15px;
  color: variables.$error-color;
  font-size: 12px;
  text-align: center;
}

.inner-submenu {
  max-height: 300px;
  overflow-y: auto;
}
